<template>
  <div class="test">
    <el-header class="header">
      <el-row class="edit-header">
        <el-col :span="3" class="lf-bt">
          <el-popover width="0" trigger="hover">
            <el-button slot="reference" icon="el-icon-edit" @click="open_1"
              >Edit User Email</el-button
            >
          </el-popover>
        </el-col>

        <el-col :span="3" class="lf-bt">
          <el-popover width="0" trigger="hover">
            <el-button
              slot="reference"
              icon="el-icon-edit-outline"
              @click="open_3"
              >Edit Industry</el-button
            >
          </el-popover>
        </el-col>

        <el-col :span="4" class="lf-bt">
          <el-popover width="0" trigger="hover">
            <el-button
              slot="reference"
              icon="el-icon-edit-outline"
              @click="open_4"
              >Edit Company Type</el-button
            >
          </el-popover>
        </el-col>

        <el-col :span="3" class="lf-bt">
          <el-popover width="0" trigger="hover">
            <el-button
              slot="reference"
              icon="el-icon-edit-outline"
              @click="open_5"
              >Edit Balance Change</el-button
            >
          </el-popover>
        </el-col>

        <el-col :span="2" class="button"
          ><el-button type="warning" icon="el-icon-receiving" @click="save"
            >Save</el-button
          ></el-col
        >

        <el-dialog
          :title="industryDialogTitle"
          :visible.sync="industryDialogVisible"
          class="dialog"
        >
          <el-form ref="form" label-width="100px">
            <el-form-item label="Industry" label-width="120px">
              <el-select
                v-model="industryTemp"
                placeholder="Select Industry"
                @change="industryTempChange"
              >
                <el-option
                  v-for="item in industryType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="industryDialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="handleConfirm">Confirm</el-button>
          </span>
        </el-dialog>

        <el-dialog
          :title="companyDialogTitle"
          :visible.sync="companyDialogVisible"
          class="dialog"
        >
          <el-form ref="form" label-width="100px">
            <el-form-item label="Company" label-width="120px">
              <el-select
                v-model="companyTemp"
                placeholder="Select Company Type"
                @change="companyTempChange"
              >
                <el-option
                  v-for="item in companyType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="companyDialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="handleConfirm2"
              >Confirm</el-button
            >
          </span>
        </el-dialog>
      </el-row>
    </el-header>

    <el-container class="container">
      <el-main class="main">
        <div class="ques">
          <div class="title">
            Creating license for User
          </div>
          <div class="title"></div>
          <div class="title">Email: {{ email }}</div>
          <div class="title"></div>
          <div class="title">Industry: {{ industry }}</div>
          <div class="title"></div>
          <div class="title">Company Type: {{ company }}</div>
          <div class="title"></div>
          <div class="title">Balance Change: {{ balance }}</div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "test",
  data() {
    return {
      email: "",
      industry: "",
      company: "",
      industryTemp: "",
      companyTemp: "",
      industryType: [],
      industryDialogVisible: false,
      industryDialogTitle: "Industry Select",
      companyType: [],
      companyDialogVisible: false,
      companyDialogTitle: "Company Select",
      balance: "",
    };
  },
  components: {
    // editHeader,
  },
  methods: {
    open_1: function() {
      var inputValue = "";
      if (this.title != "Please add user email") {
        inputValue = this.title;
      }
      this.$prompt("Please input user email:", "Email", {
        inputValue: inputValue,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        inputPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        inputErrorMessage: "This is not a valid email format",
      })
        .then(({ value }) => {
          this.changeEmail(value);
        })
        .catch(() => {});
    },
    open_2: function() {
      this.$prompt("Please input company type description", "Description", {
        inputValue: this.description,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      })
        .then(({ value }) => {
          this.changeDescription(value);
        })
        .catch(() => {});
    },
    open_3: function() {
      this.industryDialogVisible = true;
      this.industryTemp = this.industry;
    },
    open_4: function() {
      this.companyDialogVisible = true;
      this.companyTemp = this.company;
    },
    open_5: function() {
      this.$prompt(
        "Please input the balance amount change, positive for adding balance, negative for decreasing balance:",
        "Balance",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          inputType: "number",
          inputPattern: /\S/,
          inputErrorMessage: "Balance change amount cannot be empty",
        }
      )
        .then(({ value }) => {
          this.changeBalance(value);
        })
        .catch(() => {});
    },
    changeEmail: function(value) {
      this.email = value;
    },
    changeBalance: function(value) {
      this.balance = value;
    },
    industryTempChange(value) {
      this.industryTemp = value;
    },
    companyTempChange(value) {
      this.companyTemp = value;
    },
    industryChange(value) {
      this.industry = value;
    },
    companyChange(value) {
      this.company = value;
    },
    handleConfirm() {
      this.industryDialogVisible = false;
      if (this.industryTemp != "") this.industryChange(this.industryTemp);
    },
    handleConfirm2() {
      this.companyDialogVisible = false;
      if (this.companyTemp != "") this.companyChange(this.companyTemp);
    },
    save() {
      // this.saveinfo('save');
      if (
        this.email == "" ||
        this.industry == "" ||
        this.company == "" ||
        this.balance == ""
      ) {
        this.$message.error("Please input all values");
        return;
      }

      // get user id according to email
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.cn/api/user/v0.1/user_admin/?email=${this.email}`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      }).then((res) => {
        let user_id = "";
        let user_list = res.data.data.user_list;
        for (let x = 0; x < user_list.length; x++) {
          if (user_list[x].email == this.email) {
            user_id = user_list[x].id;
          }
        }
        if (user_id == "") {
          this.$message.error("Account for this email doesn't exist.");
          return;
        }
        // get license list according to user id
        this.$axios({
          method: "get",
          url: `https://api.oxvalue.cn/api/license/v0.1/user_license_admin/?user=${user_id}`,
          headers: {
            Authorization: window.localStorage.getItem("ova_admin_token"),
          },
        }).then((res1) => {
          let license_list = res1.data.data.license_list;
          let license_id = "";
          console.log(license_list);
          for (let x = 0; x < license_list.length; x++) {
            // if license already exist
            if (
              license_list[x].fk_company_type_id == this.company &&
              license_list[x].fk_industry_id == this.industry
            ) {
              license_id = license_list[x].id;
              break;
            }
          }
          if (license_id == "") {
            // if lisence doesn't exist, create
            let post_data = {
              fk_user_id: user_id,
              fk_industry_id: this.industry,
              fk_company_type_id: this.company,
              balance: this.balance,
            };
            this.$axios({
              method: "post",
              url:
                "https://api.oxvalue.cn/api/license/v0.1/user_license_admin/",
              headers: {
                Authorization: window.localStorage.getItem("ova_admin_token"),
              },
              data: JSON.stringify(post_data),
            }).then((res2) => {
              if (res2.data.code == "200") {
                this.$message.success("Lisence created successfully!");
                (this.industry = ""), (this.company = "");
                this.balance = "";
              } else {
                this.$message.error("API error!");
              }
            });
          } else {
            // license exists, update
            let post_data = {
              fk_user_id: user_id,
              fk_industry_id: this.industry,
              fk_company_type_id: this.company,
              balance_increase: this.balance,
            };
            this.$axios({
              method: "put",
              url: `https://api.oxvalue.cn/api/license/v0.1/user_license_admin/${license_id}/`,
              headers: {
                Authorization: window.localStorage.getItem("ova_admin_token"),
              },
              data: JSON.stringify(post_data),
            }).then((res2) => {
              if (res2.data.code == "200") {
                this.$message.success("Lisence updated successfully!");
                (this.industry = ""), (this.company = "");
                this.balance = "";
              } else {
                this.$message.error("API error!");
              }
              return;
            });
          }
        });
      });
    },
  },
  computed: {},
  created() {
    this.$axios({
      method: "get",
      url: "https://api.oxvalue.cn/api/question/v0.1/industry_admin/",
      headers: {
        Authorization: window.localStorage.getItem("ova_admin_token"),
      },
    }).then((res) => {
      if (res.data["code"] == 403) {
        this.$store.dispatch("clear");
        window.localStorage.removeItem("ova_admin_token");
        this.$router.push("/login");
        return;
      }
      this.industryType = [];
      for (let i in res.data.data.industry_list) {
        this.industryType.push({
          label:
            res.data.data.industry_list[i].id +
            ". " +
            res.data.data.industry_list[i].name,
          value: res.data.data.industry_list[i].id,
        });
      }
    });
    this.$axios({
      method: "get",
      url: "https://api.oxvalue.cn/api/question/v0.1/company_type_admin/",
      headers: {
        Authorization: window.localStorage.getItem("ova_admin_token"),
      },
    }).then((res) => {
      if (res.data["code"] == 403) {
        this.$store.dispatch("clear");
        window.localStorage.removeItem("ova_admin_token");
        this.$router.push("/login");
        return;
      }
      this.companyType = [];
      for (let i in res.data.data.company_type_list) {
        this.companyType.push({
          label:
            res.data.data.company_type_list[i].id +
            ". " +
            res.data.data.company_type_list[i].name,
          value: res.data.data.company_type_list[i].id,
        });
      }
    });
  },
  mounted() {},
  beforeDestroy() {},
};
</script>

<style>
.test .linkDialog {
  text-align: left;
}
.test .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test header {
  padding: 0 100px;
}

.test .el-container {
  padding: 0 100px;
}

.test .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .main {
  border-top: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test .outline {
  overflow: auto;
  height: 550px;
}

.test .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test .type-icon:hover {
  color: #409eff;
  cursor: pointer;
}

.test .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test .el-tabs__header {
  margin: 0;
}

.test .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

.test .main {
  max-height: 610px;
}

.test .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test .ques-block:hover {
  background: #f5f5f5;
  transition: 0.3s;
}

.test .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test .el-button-group > .el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test .el-button-group > .el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test .dialog {
  text-align: left;
  font-size: large;
}
.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
}
.test .deleteOptionButton {
  margin-left: 20px;
}

.test .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test .important {
  color: crimson;
}

.test .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test .logic-bt {
  text-align: center;
}

.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
  margin-bottom: 22px;
}

.test .deleteOptionButton {
  margin-left: 20px;
}

.test .settingDialog {
  text-align: center;
}

.test .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test .logic-form .el-input--suffix .el-input__inner {
  width: 620px;
}

.test .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>
